import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';
import CryptoJS from 'crypto-js'

	const encryptedModules = localStorage.getItem('modulePermissions');
	let modulePermissions = undefined;
	if(encryptedModules) {
		const bytes = CryptoJS.AES.decrypt(encryptedModules, process.env.REACT_APP_MODULE_ENCRYPTION_KEY);
		const decryptedState = bytes.toString(CryptoJS.enc.Utf8);
		modulePermissions = JSON.parse(decryptedState);
	} else {
		modulePermissions = undefined;
	}

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/login')
		),
	},
	{
		key: 'login-1',
		path: `${AUTH_PREFIX_PATH}/login-1`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/login-1')
		),
	},
	{
		key: 'two-factor',
		path: `${AUTH_PREFIX_PATH}/two-factor`,
		component: React.lazy(() => import('views/auth-views/authentication/two-factor')),
	},
	{
		key: 'register-1',
		path: `${AUTH_PREFIX_PATH}/register-1`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/register-1')
		),
	},
	{
		key: 'forgot-password',
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/forgot-password')
		),
	},
	{
		key: 'request-demo',
		path: `${AUTH_PREFIX_PATH}/request-demo`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/request-demo')
		),
	},
	{
		key: 'first-login',
		path: `${AUTH_PREFIX_PATH}/first-login`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/first-login')
		),
	},
];

export const protectedRoutes = [
	...(modulePermissions && modulePermissions?.includes("DASHBOARD") ? [{
		key: 'dashboard.default',
		path: `${APP_PREFIX_PATH}/dashboards/default`,
		component: React.lazy(() => import('views/app-views/dashboards/default')),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("DOMAIN_IP") ? [{
		key: 'my-assets',
		path: `${APP_PREFIX_PATH}/dashboards/my-assets`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/my-assets')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("DOMAIN_IP") ? [{
		key: 'my-assets-details',
		path: `${APP_PREFIX_PATH}/dashboards/my-assets/details/:id`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/my-assets/details')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("DOMAIN_IP") ? [{
		key: 'my-assets-issues-detail',
		path: `${APP_PREFIX_PATH}/dashboards/my-assets/issues/detail/:id`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/issues-detail')
		),
	}]: []),
	// {
	// 	key: 'dashboards-query-network',
	// 	path: `${APP_PREFIX_PATH}/dashboards/domain/query-network`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/query-network')
	// 	),
	// },
	// {
	// 	key: 'dashboards-query-details',
	// 	path: `${APP_PREFIX_PATH}/dashboards/query-details/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/query-details')
	// 	),
	// },
	...(modulePermissions && modulePermissions?.includes("SCAN_HISTORY") ? [{
		key: 'dashboards-asset-history',
		path: `${APP_PREFIX_PATH}/dashboards/asset/asset-history`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/asset-history')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("SCAN_HISTORY") ? [{
		key: 'dashboards-selected-asset-history',
		path: `${APP_PREFIX_PATH}/dashboards/asset-history/:id`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/asset-history-selected')
		),
	}]: []),
	// {
	// 	key: 'dashboards-scan-comparison',
	// 	path: `${APP_PREFIX_PATH}/dashboards/scan/scan-comparison`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/scanComparison')
	// 	),
	// },
	...(modulePermissions && modulePermissions?.includes("SSL_CERTIFICATES") ? [{
		key: 'dashboards-ssl-certificates',
		path: `${APP_PREFIX_PATH}/dashboards/ssl/ssl-certificates`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/ssl')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("TECHNOLOGIES") ? [{
		key: 'dashboards-technologies-technology',
		path: `${APP_PREFIX_PATH}/dashboards/technologies`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/technologies')
		),
	}]: []),
	// ...(modulePermissions && modulePermissions?.includes("SEARCH") ? [{
	// 	key: 'dashboards-search',
	// 	path: `${APP_PREFIX_PATH}/dashboards/cve/search`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/cve-search')
	// 	),
	// }]: []),
	// ...(modulePermissions && modulePermissions?.includes("BROWSE") ? [{
	// 	key: 'dashboards-ssl-browse',
	// 	path: `${APP_PREFIX_PATH}/dashboards/browse`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/browse')
	// 	),
	// }]: []),
	...(modulePermissions && modulePermissions?.includes("OSINT") ? [{
		key: 'dashboards-osint',
		path: `${APP_PREFIX_PATH}/dashboards/osint`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/osint')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("OSINT") ? [{
		key: 'dashboards-osint-domainscans-eventresult',
		path: `${APP_PREFIX_PATH}/dashboards/osint/domainscans/eventresults/:name/:id/:event`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/domainEventResults')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("OSINT") ? [{
		key: 'dashboards-osint-domainscans-scansummary',
		path: `${APP_PREFIX_PATH}/dashboards/osint/domainscans/scansummary/:name/:id`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/domainScansSummary')
		),
	}]: []),
	// {
	// 	key: 'dashboards-reports-executiveSummaryReports',
	// 	path: `${APP_PREFIX_PATH}/dashboards/reports/executiveSummaryReports`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/executiveSummaryReports')
	// 	),
	// },
	// ...(modulePermissions && modulePermissions?.includes("SERVICES") ? [{
	// 	key: 'dashboards-port-services',
	// 	path: `${APP_PREFIX_PATH}/dashboards/port-services`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/port-services')
	// 	),
	// }]: []),
	...(modulePermissions && modulePermissions?.includes("INSTANT_LOOKUP") ? [{
		key: 'dashboards-instant',
		path: `${APP_PREFIX_PATH}/dashboards/instant`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/instant')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("DETECTED") ? [{
		key: 'dashboards-all-vulnerabilities',
		path: `${APP_PREFIX_PATH}/dashboards/all-vulnerabilities`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/all-vulnerabilities')
		),
	}]: []),
	// ...(modulePermissions && modulePermissions?.includes("DETECTED") ? [{
	// 	key: 'dashboards-cve-details',
	// 	path: `${APP_PREFIX_PATH}/dashboards/cve/cve-details/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/cve-details')
	// 	),
	// }]: []),
	// {
	// 	key: 'dashboards-cve-name',
	// 	path: `${APP_PREFIX_PATH}/dashboards/cve/detected-vulnerability-name/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/dejected-vulnerability-name')
	// 	),
	// },
	// {
	// 	key: 'dashboards-detected-vulnerability-table',
	// 	path: `${APP_PREFIX_PATH}/dashboards/cve/vulnerability-table/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/cve/components/DetectedVulnerabilityTable')
	// 	),
	// },
	// {
	// 	key: 'dashboards-cve-version',
	// 	path: `${APP_PREFIX_PATH}/dashboards/cve/detected-vulnerability-version/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/dejected-vulnerability-version')
	// 	),
	// },
	...(modulePermissions && modulePermissions?.includes("WEBSITE_SECURITY_SCANNER") ? [{
		key: 'apps-website-security-checker',
		path: `${APP_PREFIX_PATH}/apps/website-security-checker`,
		component: React.lazy(() => import('views/app-views/dashboards/url-security-checker')),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("SCRUM_BOARD") ? [{
		key: 'dashboards-scrumboard-board',
		path: `${APP_PREFIX_PATH}/dashboards/scrumboard/board`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/scrumboard')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("PERFORMANCE_TESTS") ? [{
		key: 'dashboards-performance-test',
		path: `${APP_PREFIX_PATH}/dashboards/performance/performance-test`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/performance')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("PERFORMANCE_TESTS") ? [{
		key: 'dashboards-performance-test-result',
		path: `${APP_PREFIX_PATH}/dashboards/performance/performance-test-result`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/performancetestresult')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("PERFORMANCE_TESTS") ? [{
		key: 'dashboards-performance-ssl-result',
		path: `${APP_PREFIX_PATH}/dashboards/performance/performance-ssl-result`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/ssl-analysis-result')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("PERFORMANCE_TESTS") ? [{
		key: 'dashboards-performance-ssl-analysis-result',
		path: `${APP_PREFIX_PATH}/dashboards/performance/performance-ssl-analysis-result/:id`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/ssl-reports')
		),
	}]: []),
	// {
	// 	key: 'dashboards-social-scan',
	// 	path: `${APP_PREFIX_PATH}/dashboards/social/scan`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/socialScan')
	// 	),
	// },
	...(modulePermissions && modulePermissions?.includes("SOCIAL_MEDIA") ? [{
		key: 'dashboards-social-select',
		path: `${APP_PREFIX_PATH}/dashboards/social/scan/select`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/socialScanSelect')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("SOCIAL_MEDIA") ? [{
		key: 'dashboards-social-results',
		path: `${APP_PREFIX_PATH}/dashboards/social/scan/results`,
		component: React.lazy(() =>
			import('views/app-views/dashboards/socialScanResults')
		),
	}]: []),
	// {
	// 	key: 'dashboards-ssl-details',
	// 	path: `${APP_PREFIX_PATH}/dashboards/ssl/ssl-details/:id`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/ssl-details')
	// 	),
	// },
	...(modulePermissions && modulePermissions?.includes("DEPARTMENT") ? [{
		key: 'apps-user-group',
		path: `${APP_PREFIX_PATH}/apps/user-group/*`,
		component: React.lazy(() => import('views/app-views/dashboards/user-group')),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("EMAIL_LEAKAGE") ? [{
		key: 'email-leakage',
		path: `${APP_PREFIX_PATH}/email-leakage`,
		component: React.lazy(() => import('views/app-views/dashboards/email-leakage')),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("EMAIL_LEAKAGE") ? [{
		key: 'email-leakage-detail',
		path: `${APP_PREFIX_PATH}/email-leakage/domains/:name/:id`,
		component: React.lazy(() =>
				import('views/app-views/dashboards/email-leakage/domains')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("EMAIL_LEAKAGE") ? [{
		key: 'email-leakage-detail',
		path: `${APP_PREFIX_PATH}/email-leakage/details/:id`,
		component: React.lazy(() =>
				import('views/app-views/dashboards/email-leakage/details')
		),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("FRAUD_DETECTION") ? [{
		key: 'apps-fraud-detection',
		path: `${APP_PREFIX_PATH}/fraud-detection`,
		component: React.lazy(() => import('views/app-views/dashboards/fraud-detection')),
	}]: []),
	...(modulePermissions && modulePermissions?.includes("NEWS") ? [{
		key: 'news',
		path: `${APP_PREFIX_PATH}/news`,
		component: React.lazy(() => import('views/app-views/dashboards/news')),
	}]: []),
	{
		key: 'settings',
		path: `${APP_PREFIX_PATH}/settings`,
		component: React.lazy(() => import('views/app-views/dashboards/settings-page')),
	},
	{
		key: 'help',
		path: `${APP_PREFIX_PATH}/help`,
		component: React.lazy(() => import('views/app-views/dashboards/help-page')),
	},
	// {
	// 	key: 'dashboards-port-versions',
	// 	path: `${APP_PREFIX_PATH}/dashboards/port-versions/:name`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/dashboards/port-versions')
	// 	),
	// },
];

