import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vulnerabilityService from "services/vulnerabilityService";

export const initialState = {
  loading: false,
  error: "",
  success: false,
  message: "",
  messageType: "",
  showMessage: false,
  serviceList: [],
  vulnerability: [],
  detectedVulnerability: [],
  searchVulnerability: null,
  detectedVulnerabilityName: [],
  detectedVulnerabilityVersion: [],
  vulnerabilityLoading: false,
  allVulnerabilities: [],
  allVulnerabilitiesLoading: false
};

export const getServiceList = createAsyncThunk(
  "vulnerability/getServiceList",
  async ( _, { rejectWithValue }) => {
    try {
      const response = await vulnerabilityService.getServiceList();
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVulnerability = createAsyncThunk(
  "vulnerability/getVulnerability",
  async (_,{ rejectWithValue }) => {
    try {
      const response = await vulnerabilityService.getVulnerability();
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVulnerabilityDetected = createAsyncThunk(
  "vulnerability/detected",
  async (rejectWithValue) => {
    try {
      return await vulnerabilityService.getVulnerabilityDetected();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const vulnerableById = createAsyncThunk(
  "vulnerability/vulnerableById",
  async (id) => {
    try {
      return await vulnerabilityService.vulnerableById(id);
    } catch (error) {
      console.log(error);
    }
  }
);

export const vulnerableByPort = createAsyncThunk(
  "vulnerability/vulnerableByPort",
  async (id) => {
    try {
      return await vulnerabilityService.vulnerableByPort(id);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSearchVulnerability = createAsyncThunk(
  "vulnerability/search",
  async (search, { rejectWithValue }) => {
    try {
      return await vulnerabilityService.getSearchVulnerability(search);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllVulnerabilities = createAsyncThunk(
    "vulnerability/getAllVulnerabilities",
    async (data, { rejectWithValue }) => {
      try {
        return await vulnerabilityService.getAllVulnerabilities(data?.params, data?.data);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
);

export const addVulnerability = createAsyncThunk(
  "vulnerability/addVulnerability",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await vulnerabilityService.addVulnerability(data);
      const response2 = await vulnerabilityService.getVulnerability(data);
      dispatch(addVulnerabilitySuccess(response2.data));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.status === "50") {
        return rejectWithValue(
          dispatch(addVulnerabilityError("50")) // Vulnerability already exist
        );
      }
      if (error.response && error.response.data.status === "10005") {
        return rejectWithValue(
          dispatch(
            addVulnerabilityError("10005") //Score must be less than or equal to 100
          )
        );
      }

      return rejectWithValue(
        dispatch(addVulnerabilityError(error.response?.data?.error || "400"))
      );
    }
  }
);


export const saveVulnerability = createAsyncThunk(
  "vulnerability/saveVulnerability",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await vulnerabilityService.saveVulnerability(data);
      dispatch(saveVulnerabilitySuccess(response?.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(
        dispatch(saveVulnerabilityError(error.response?.data?.error || "400"))
      );
    }
  }
);

export const vulnerabilitySlice = createSlice({
  name: "vulnerability",
  initialState,
  reducers: {
    serviceListSuccess: (state, action) => {
      state.serviceList = action.payload;
      state.loading = false;
    },
    serviceListError: (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = false;
      state.message = action.payload || "400";
      state.showMessage = false;
      state.messageType = "error";
    },
    getVulnerabilitySuccess: (state, action) => {
      state.vulnerability = action.payload;
      state.loading = false;
      state.success = true;
      state.message = "200";
      state.messageType = "success";
      state.showMessage = false;
      state.error = "";
    },
    getVulnerabilityError: (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = false;
      state.message = action.payload || "400";
      state.messageType = "error";
      state.showMessage = false;
    },
    addVulnerabilitySuccess: (state, action) => {
      state.vulnerability = action.payload;
      state.loading = false;
      state.success = true;
      state.message = "200"; 
      state.messageType = "success";
      state.showMessage = true;
      state.error = "";
    },
    addVulnerabilityError: (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = false;
      state.message = action.payload || "400";
      state.showMessage = true;
      state.messageType = "error";
    },
    saveVulnerabilitySuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = "200"; 
      state.messageType = "success";
      state.showMessage = true;
      state.error = "";
    },
    saveVulnerabilityError: (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = false;
      state.message = action.payload || "400";
      state.showMessage = true;
      state.messageType = "error";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getServiceList.fulfilled, (state, action) => {
      state.serviceList = action.payload;
      state.loading = false;
    });
    builder.addCase(getServiceList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getVulnerability.fulfilled, (state, action) => {
      state.vulnerability = action.payload;
      state.vulnerabilityLoading = false;
    });
    builder.addCase(getVulnerability.rejected, (state, action) => {
      state.vulnerabilityLoading = false;
    });
    builder.addCase(getVulnerability.pending, (state, action) => {
      state.vulnerabilityLoading = true;
    });
    builder.addCase(addVulnerability.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveVulnerability.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vulnerableById.fulfilled, (state, action) => {
      state.detectedVulnerabilityName = action.payload.data.content;
      state.loading = false;
    });
    builder.addCase(vulnerableById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vulnerableById.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(vulnerableByPort.fulfilled, (state, action) => {
      state.detectedVulnerabilityVersion = action.payload.data.content;
      state.loading = false;
    });
    builder.addCase(vulnerableByPort.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vulnerableByPort.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getVulnerabilityDetected.fulfilled, (state, action) => {
      state.detectedVulnerability = action.payload.data.content;
      state.loading = false;
    });

    builder.addCase(getVulnerabilityDetected.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getVulnerabilityDetected.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSearchVulnerability.fulfilled, (state, action) => {
      state.searchVulnerability = action.payload.data;
      state.loading = false;
    });

    builder.addCase(getSearchVulnerability.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getSearchVulnerability.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllVulnerabilities.fulfilled, (state, action) => {
      state.allVulnerabilities = action.payload.data;
      state.allVulnerabilitiesLoading = false;
    });

    builder.addCase(getAllVulnerabilities.pending, (state, action) => {
      state.allVulnerabilitiesLoading = true;
    });

    builder.addCase(getAllVulnerabilities.rejected, (state, action) => {
      state.allVulnerabilitiesLoading = false;
    });
  },
});

export const {
  serviceListError,
  serviceListSuccess,
  getVulnerabilityError,
  getVulnerabilitySuccess,
  addVulnerabilityError,
  addVulnerabilitySuccess,
  saveVulnerabilitySuccess,
  saveVulnerabilityError,
} = vulnerabilitySlice.actions;

export default vulnerabilitySlice.reducer;
